import { graphql, useStaticQuery } from "gatsby"
import React from "react"
// @ts-ignore
import { HelmetDatoCms } from "gatsby-source-datocms"

export const Favicon: React.FC = () => {
  const data = useStaticQuery(query)
  return <HelmetDatoCms favicon={data.datoCmsSite.faviconMetaTags} />
}

const query = graphql`
  {
    datoCmsSite {
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
  }
`
