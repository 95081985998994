import styled, { css } from "styled-components"
import device from "../Theme/devices"
import { colors } from "../Theme/colors"
import React from "react"
import { GridCellProps, GridLayoutProps, HMarginValue } from "./GridLayout"

export const defaultGap = '20px';

const PlainGridLayout = styled.div<GridLayoutProps>`
  display: flex;
  flex-direction: column;
  @media (${device.desktop}) {
    &:first-of-type {
      *[data-headerOverlay="false"] {
        margin-top: ${(3 + 42 + 25) + 25}px;
      }
    }
  }
  @media (${device.tablet}) {
    display: grid;
    grid-template-columns: 10px repeat(6, 1fr) 10px;
    grid-column-gap: ${defaultGap};
  }

  @media (${device.desktop}) {
    grid-template-columns: 10px repeat(12, 1fr) 10px;
  }
  ${props => props.vMargins};
`

const GreenGridLayout = styled(PlainGridLayout).attrs({
  className: "green",
})<GridLayoutProps>`
  ${props =>
    props.greenish && css`
      ${(!props.onlyPartlyGreenish
      ? css`
          background: ${colors.veryPaleGreen};
        `
      : css`
          background: linear-gradient(
            to bottom,
            ${colors.veryPaleGreen} 0%,
            ${colors.veryPaleGreen} ${props.partlyAt || 50}%,
            transparent ${props.partlyAt || 50}%
          );
        `)}
    `};
`

const hMarginCss = (prop?: string, value?: HMarginValue) => {
  if (!prop || !value) return null
  switch (value) {
    case "all":
      return css`
        ${prop}: 20px;
      `
    case "phone-only":
      return css`
        ${prop}: 20px;
        @media (${device.tablet}) {
          ${prop}: 0;
        }
      `
    case "mobile":
      return css`
        ${prop}: 20px;
        @media (${device.desktop}) {
          ${prop}: 0;
        }
      `
    case "desktop":
      return css`
        @media (${device.desktop}) {
          ${prop}: 20px;
        }
      `
    default:
      return css`
        ${prop}: ${value};
      `
  }
}

export const GridCell = styled.div<GridCellProps>`
  @media (${device.tablet}) {
    grid-column: ${props => props.tabletCol};
    grid-row: ${props => props.tabletRow};
  }
  @media (${device.desktop}) {
    grid-column: ${props => props.column};
    grid-row: ${props => props.row};
  }
  ${({ leftMargin }) =>
    ["mobile", "all"].includes(leftMargin || "") &&
    css`
      margin-left: 20px;
    `}
  ${props => hMarginCss("margin-left", props.leftMargin)}
  ${props => hMarginCss("margin-right", props.rightMargin)}
`

export const GridLayout: React.FC<GridLayoutProps> = React.forwardRef(
  (props, ref) =>
    props.greenish ? (
      //@ts-ignore
      <GreenGridLayout ref={ref} {...props} />
    ) : (
      //@ts-ignore
      <PlainGridLayout ref={ref} {...props} />
    )
)
